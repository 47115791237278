import { render, staticRenderFns } from "./NewSub.vue?vue&type=template&id=52f7bc1c&"
import script from "./NewSub.vue?vue&type=script&lang=js&"
export * from "./NewSub.vue?vue&type=script&lang=js&"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  null,
  null
  
)


    import installComponents from "!D:/IIS/rentmagic.website.vue.vlaams-brabant/prod/node_modules/bootstrap-vue-loader/lib/runtime/installComponents.js"
    import {BFormInput, BCol, BFormText, BRow, BCard, BAlert, BButton, BForm, BContainer} from 'bootstrap-vue'
    installComponents(component, {BFormInput, BCol, BFormText, BRow, BCard, BAlert, BButton, BForm, BContainer})
    

export default component.exports